import React from "react"
import { Global, css, jsx } from "@emotion/core"
import { Link } from "gatsby"
import Image from "gatsby-image"
import ReadLink from "../components/read-link"

const BlogSub = ({ post }) => (
  <div
    css={css`
      width: 80%;
      height: 100px;
      display: grid;
      grid-template-columns: 2fr 6fr;
      margin-bottom: 20px;
      font-family: "Oxygen", sans-serif;
      > div {
        border-radius: 5px 0 0 5px;
      }
    `}
  >
    <Image
      fluid={post.image.sharp.fluid}
      css={css`
        object-fit: cover;
      `}
    />
    <div
      css={css`
        height: 100px;
        margin-top: 0;
        padding-top: 0;
      `}
    >
      <Link
        to={post.slug}
        css={css`
          text-decoration: none;
        `}
      >
        <h2
          css={css`
            height: 80px;
            margin-top: 0;
            padding: 10px;
            font-size: 1.25em;
            text-decoration: none;
            color: #232323;

            @media (min-width: 420px and max-width: 830px) {
              font-size: 1.5em;
            }

            @media (min-width: 831px) {
              font-size: 2.75em;
            }
            border-radius: 0 5px 5px 0;
            :hover {
              background-color: skyblue;
              color: white;
            }
          `}
        >
          {post.title}
        </h2>
      </Link>
    </div>
  </div>
)

export default BlogSub

import React from "react"
import { Global, css, jsx } from "@emotion/core"
import ContentPreview from "../components/ContentPreview"
import Sidebar from "../components/sidebar"
import Helmet from "react-helmet"
import useSiteMetadata from "../hooks/use-sitemetadata"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <>
      <Global />

      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css?family=PT+Serif|Oxygen:700&display=swap"
          rel="stylesheet"
        />
        <script src="https://kit.fontawesome.com/7c7a24acf9.js"></script>
      </Helmet>

      <main
        css={css`
          color: #232323;
          display: grid;
          @media (min-width: 420px) {
            grid-template-columns: 1fr 3fr;
          }
        `}
      >
        <Sidebar />
        {children}
      </main>
    </>
  )
}

export default Layout

import React from "react"
import Link from "gatsby"
import { Global, css, jsx } from "@emotion/core"
import Layout from "../components/layout"

export default () => {
  return (
    <>
      <Layout>
        <div
          css={css`
            margin-left: 30px;
            width: 75%;

            p {
              font-family: "PT Serif", serif;
              padding-left: 5px;
            }
          `}
        >
         
          <h1
            css={css`
              font-size: 2.5em;
              font-family: "Oxygen", sans-serif;
              width: calc(100%-20px);
              background-color: skyblue;
              border-radius: 5px;
              color: white;
              padding: 20px;
            `}
          >
            About Me
          </h1>

          <p>
            Usually when someone starts a blog, they put together a website,
            usually from an existing theme or through a platform such as Medium
            in order to start writing. I’ve done it the other way around. I’ve
            started a blog in order to learn how to build this website.
          </p>
          <p>
            I’ve been learning web development (HTML, CSS and Javascript) in my
            spare time and lunch hours since early 2017. This blog will
            catalogue what I learn in addition to anything else that takes my
            interest, from video games to politics (the day job).
          </p>
          <p>
            Links will be broken, pages will not load and things won’t work as
            they are supposed to, but that’s ok. I’m learning. Every line of
            code that sits behind this website was put together by me, from
            scratch. Over time it will get better, features will be added and
            I’ll get there.
          </p>
          <p>
            If you have any questions, you can contact me using the online form
            here.
          </p>
        </div>
      </Layout>
    </>
  )
}

import React from "react"
import { Link } from "gatsby"
import { Global, css, jsx } from "@emotion/core"
import styled from "@emotion/styled"

const NavLink = styled(Link)`
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-decoration: none;
  text-align: center;
  color: #232323;

  font-family: "Oxygen", sans-serif;
  :hover {
    color: white;
    background-color: skyblue;
  }
`

const Nav = () => (
  <div
    css={css`
      margin-top: 30px;
      width: 75%;
    `}
  >
    <NavLink to="/" activeClassName="current-page">
      Home
    </NavLink>
    <NavLink to="/about" activeClassName="current-page">
      About
    </NavLink>
    <NavLink to="/contact" activeClassName="current-page">
      Contact
    </NavLink>
  </div>
)

export default Nav

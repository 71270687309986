import styled from "@emotion/styled"
import { Link } from "gatsby"

const ReadLink = styled(Link)`
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Oxygen", sans-serif;
  color: white;
  padding: 10px;
  background-color: hotpink;
  border-radius: 5px;
`

export default ReadLink

import React from "react"
import { Global, css, jsx } from "@emotion/core"
import GetFirstPost from "../hooks/get-first-post"
import BlogOne from "./BlogOne"
import GetSubPosts from "../hooks/get-sub-post"
import BlogSub from "./BlogSub"
import GetRestPosts from "../hooks/get-rest-post"
import BlogRest from "./BlogRest"

const ContentPreview = () => {
  const firstPost = GetFirstPost()
  console.log(firstPost)
  const subPost = GetSubPosts()
  const restPost = GetRestPosts()
  return (
    <div
      css={css`
        margin-top: 25px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: "Oxygen";
      `}
    >
      <h2
        css={css`
          text-align: center;
          width: 80%;
          margin-bottom: 15px;
          color: white;
          background-color: skyblue;

          border-radius: 5px;
        `}
      >
        Latest Post
      </h2>
      {firstPost.map(post => (
        <BlogOne key={post.slug} post={post} />
      ))}

      <h2
        css={css`
          text-align: center;
          width: 80%;
          margin-bottom: 15px;
          color: white;
          background-color: hotpink;

          border-radius: 5px;
        `}
      >
        Recent Posts
      </h2>
      {subPost.map(post => (
        <BlogSub key={post.slug} post={post} />
      ))}

      <h2
        css={css`
          text-align: center;
          width: 80%;
          margin-bottom: 15px;
          color: white;
          background-color: skyblue;

          border-radius: 5px;
        `}
      >
        Older Posts
      </h2>
      {restPost.map(post => (
        <BlogRest key={post.slug} post={post} />
      ))}
    </div>
  )
}

export default ContentPreview

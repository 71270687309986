import React from "react"
import { Global, css, jsx } from "@emotion/core"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import ReadLink from "../components/read-link"

const BlogRest = ({ post }) => (
  <div
    css={css`
      width: 80%;
    `}
  >
    <div
      css={css`
        margin-top: 0;
        padding-top: 0;
      `}
    >
      <Link
        to={post.slug}
        css={css`
          text-decoration: none;
        `}
      >
        <h2
          css={css`
            margin-top: 0;
            padding: 10px;
            font-size: 1.25em;
            text-decoration: none;
            color: #232323;
            border-radius: 5px;

            @media (min-width: 420px and max-width: 830px) {
              font-size: 1.5em;
            }

            @media (min-width: 831px) {
              font-size: 2.75em;
            }
            :hover {
              background-color: hotpink;
              color: white;
            }
          `}
        >
          {post.title}
        </h2>
      </Link>
      <h3>{post.date}</h3>
      <ReadLink
        to={post.slug}
        css={css`
          padding-left: 10px;
        `}
      >
        Read this post &rarr;
      </ReadLink>
    </div>
  </div>
)

export default BlogRest

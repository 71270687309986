import React from "react"
import { Global, css, jsx } from "@emotion/core"
import { Link } from "gatsby"
import Image from "gatsby-image"
import ReadLink from "../components/read-link"

const BlogOne = ({ post }) => (
  <div
    css={css`
      width: 80%;
      height: 200px;
      display: grid;
      grid-template-columns: 2fr 3fr;
      margin-bottom: 40px;
      > div {
        font-weight: bold;
        font-family: "Oxygen", sans-serif;
        padding: 0 0 0 0px;
        margin-top: 0;
        border-radius: 5px 0 0 5px;
      }
    `}
  >
    <Image
      fluid={post.image.sharp.fluid}
      css={css`
        object-fit: cover;
      `}
    />

    <div
      css={css`
        height: 180px;
        margin-top: 0;
        padding-top: 0;
      `}
    >
      <Link
        to={post.slug}
        css={css`
          text-decoration: none;
        `}
      >
        <h2
          css={css`
            height: 100%;
            margin-top: 0;
            padding: 10px;
            font-size: 1.25em;
            text-decoration: none;
            color: #232323;
            border-radius: 0 5px 5px 0;

            @media (min-width: 420px and max-width: 830px) {
              font-size: 1.5em;
            }

            @media (min-width: 831px) {
              font-size: 2.75em;
            }

            :hover {
              background-color: hotpink;
              color: white;
            }
          `}
        >
          {post.title}
        </h2>
      </Link>
    </div>
  </div>
)

export default BlogOne

import { graphql, useStaticQuery } from "gatsby"

const GetRestPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: [frontmatter___date], order: DESC }, skip: 3) {
        nodes {
          frontmatter {
            title
            date
            author
            slug
            image {
              sharp: childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          excerpt
        }
      }
    }
  `)

  return data.allMdx.nodes.map(post => ({
    title: post.frontmatter.title,
    author: post.frontmatter.author,
    slug: post.frontmatter.slug,
    image: post.frontmatter.image,
    excerpt: post.excerpt,
  }))
}

export default GetRestPosts

import React from "react"
import { Global, css, jsx } from "@emotion/core"

const SocialMedia = () => (
  <div
    css={css`
      display: flex;
      flex-direction: row;

      > i,
      a {
        color: #232323;
        font-size: 2em;
        padding-left: 10px;
        padding-right: 10px;
        :hover {
          color: skyblue;
        }
      }
    `}
  >
    <a href="https://www.facebook.com/RealBenMiller">
      <i class="fab fa-facebook-square"></i>
    </a>
    <a href="https://twitter.com/RealBenMiller">
      <i class="fab fa-twitter-square"></i>
    </a>
    <a href="https://www.linkedin.com/in/benjaminrmiller/">
      <i class="fab fa-linkedin"></i>
    </a>
    <a href="https://www.instagram.com/realbenmiller/">
      <i class="fab fa-instagram"></i>
    </a>
  </div>
)

export default SocialMedia

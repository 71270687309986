import React from "react"
import { Global, css, jsx } from "@emotion/core"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import BackgroundImage from "gatsby-background-image"
import SocialMedia from "../components/SocialMedia"
import Nav from "../components/Nav"

const ImageBackground = styled(BackgroundImage)`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px black;
  @media (min-width: 420px) {
    height: 200px;
    width: 200px;
  }
`

const Sidebar = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "header.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div
      css={css`
        margin-top: 25px;
        display: flex;
        border-bottom: 1px solid #232323;
        align-items: center;
        flex-direction: column;
        @media (min-width: 420px) {
          font-size: 1.5em;
          border-right: 1px solid #232323;
          padding-left: 30px;
          padding-right: 30px;
          border-bottom: none;
        }
      `}
    >
      <Link to="/">
        <ImageBackground
          tag="div"
          fluid={image.sharp.fluid}
          css={css`
            :before,
            :after {
              border-radius: inherit;
            }
            border-radius: 50%;
          `}
        />
      </Link>

      <h1
        css={css`
          margin-top: 0.3em;
          padding: 5px;
          font-family: "Oxygen", sans-serif;
          :hover {
            background-color: skyblue;
            color: white;
          }
        `}
      >
        Ben Miller
      </h1>

      <SocialMedia />
      <Nav />
    </div>
  )
}

export default Sidebar
